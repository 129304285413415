@tailwind base;
@tailwind components;
@tailwind utilities;

/*----------------------------------------------------------------------
# NAVBAR - NAV
-----------------------------------------------------------------------*/
.navbar-expand-xl {
  justify-content: center;
  padding: 5px 30px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  max-width: 100px;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.nav-logo-text-item {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 15px;
  color: white;
  font-family: 'Poppins';
  font-size: 1.4rem;
  cursor: pointer;
}

/* displaying until 1200px -------------------------------------------*/
.navbar-panel-description-wrapper-A {
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 3;
  padding-left: 1%;
}

.navbar-panel-description-wrapper-A {
  color: transparent;
}

.navbar-panel-description-wrapper-A:hover {
  font-weight: 550;
  text-decoration: none;
}

.navbar-panel-description-A {
  font-family: 'Poppins';
  font-size: 1.4rem;
  color: #9e9e9e;
  text-align: center;
  margin: 0px 5px;
}

@media (max-width: 1200px) {
  .navbar-panel-description-wrapper-A {
    padding: 0px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 375px) {
  .navbar-panel-description-A {
    display: none;
  }
}
/* displaying after 376px -------------------------------------------*/
.navbar-panel-description-wrapper-B {
  margin: 0px;
  color: transparent;
}

.navbar-panel-description-wrapper-B:hover {
  font-weight: 600;
  text-decoration: none;
}

.navbar-panel-description-B {
  font-family: 'Poppins';
  font-style: italic;
  font-size: 0.9rem;
  color: #9e9e9e;
  text-align: left;
  margin: 0px;
}

@media (min-width: 376px) {
  .navbar-panel-description-wrapper-B {
    display: none;
  }
}
/* links ------------------------------------------------------------*/
.navbar-tab-wrapper {
  justify-items: center;
}

.nav-link-login-icons {
  margin: 0px 0px 3px 5px;
}

.navbar-brand > img {
  padding: 7px 14px;
}

.navbar-burger {
  font-size: 20px;
  border: none;
  width: 70px;
  height: 40px;
  background-color: rgb(255, 255, 255);
}
.logo-img {
  /*padding-right: 15px;*/
  width: 120px;
}

@media (max-width: 1200px) {
  .navbar-expand-xl {
    padding: 5px 15px;
  }

  .nav-link {
    margin: 5px 0px !important;
    padding-left: 0px;
  }

  .navbar-tab-wrapper {
    align-items: center;
  }

  .navbar-burger {
    font-size: 20px;
  }
  .presenter {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .navbar-expand-xl {
    padding: 5px 15px;
  }

  .navbar-logo {
    max-width: 80px;
    width: 100px;
  }

  .nav-logo-text-item {
    font-size: 1rem;
    margin-left: 10px;
  }

  .navbar-panel-description-A {
    font-size: 1rem;
    margin: 0px;
  }

  .navbar-burger {
    font-size: 20px;
  }
  .presenter {
    text-align: center;
  }
  .logo-img {
    width: 120px;
  }
}

.remove-container-padding {
  margin: 0 -16px;
}
.remove-container-padding-tablet {
  margin: -24px 0 0 0;
}

.thumbs-wrapper {
  background-color: white;
  width: 100%;
  margin: 0px !important;
  justify-items: center;
  justify-content: center;
}

a.customButton:hover {
  background-color: #f0e548;
}

.artist-frame {
  border: black;
  border-width: 2px;
}
@media (min-width: 1200px) {
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px !important;
    width: auto !important;
    margin: auto !important;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 70vh !important;
  }
}

@media (max-width: 768px) {
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px !important;
    width: auto !important;
    margin: auto !important;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 55vh !important;
    align-items: flex-start;
  }
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: 90vw !important;
  }
}
